import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { ComplexField } from "./ComplexField";

type BaseFormikFieldProps = {
  name: string;
  label: string;
  disabled?: boolean;
  value?: any;
  isDynamic?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type SelectFieldOptions = {
  type: "select";
  options: { value: string; label: string }[];
};

type OtherFieldOptions = {
  type: "text" | "password";
};

type FormikFieldProps = BaseFormikFieldProps &
  (SelectFieldOptions | OtherFieldOptions);

export const FormikField = ({
  name,
  label,
  type,
  value,
  onChange,
  isDynamic = false,
  disabled = false,
  ...props
}: FormikFieldProps) => {
  const formik = useFormikContext();
  const values = useFormikContext().values;

  switch (type) {
    case "select": {
      const options = (props as SelectFieldOptions).options;

      return (
        <FormControl fullWidth>
          <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            // @ts-ignore
            value={values[name]}
            label={label}
            onChange={async (event) => {
              const newId = event.target.value;
              await formik.setFieldValue(name, newId);
            }}
            renderValue={(value) => {
              const option = options.find((option) => option.value === value);
              return option?.label;
            }}
            // @ts-ignore
            error={Boolean(formik.errors[name])}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption" color="error">
            {/*@ts-ignore*/}
            {formik.errors[name]}
          </Typography>
        </FormControl>
      );
    }

    default: {
      return (
        <ComplexField name={name} isDynamic={isDynamic} disabled={disabled}>
          {({ field, form, disabled }) => (
            <TextField
              {...field}
              type={type}
              name={name}
              label={label}
              // @ts-ignore
              value={value || formik.values[name]}
              onChange={(event) => {
                formik.handleChange(event);
                // @ts-ignore
                if (onChange) onChange(event);
              }}
              fullWidth
              // @ts-ignore
              error={Boolean(formik.errors[name])}
              // @ts-ignore
              helperText={formik.errors[name]}
              disabled={disabled}
            />
          )}
        </ComplexField>
      );
    }
  }
};
