import React, { useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { getChangedValues, Loading } from "shared";
import { Alert, AlertTitle } from "@mui/material";
import { useSnackbar } from "notistack";

interface GeneralDataProviderProps<T> {
  id: string;
  entityType: string;
  initialData: T;
  children: (args: { update: (data: T) => Promise<T> }) => React.JSX.Element;
  getService: ({ id }: { id: string }) => Promise<T>;
  updateService: ({ id, data }: { id: string; data: Partial<T> }) => Promise<T>;
  createService: ({ data }: { data: T }) => Promise<T>;
  onEntityCreated?: (id: string) => void;
}

export const GeneralDataProvider = <T,>({
  id,
  initialData,
  entityType,
  children,
  getService,
  updateService,
  createService,
  onEntityCreated,
}: GeneralDataProviderProps<T>) => {
  const queryClient = useQueryClient();
  const formRef = useRef<FormikProps<T>>(null);
  const { enqueueSnackbar } = useSnackbar();

  const isNew = id === "new";

  const query: UseQueryResult<T, Error> = useQuery(
    ["get", entityType, id],
    () =>
      getService({
        id,
      }),
    {
      enabled: !isNew,
    }
  );

  const updateMutation: UseMutationResult<T, Error, any> = useMutation({
    mutationFn: async (data: Partial<T>) =>
      updateService({
        id,
        data,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get", entityType, id]);
      await queryClient.invalidateQueries(["list", entityType]);
    },
    onError: (error) => {
      console.log("on error");
      try {
        const formik = formRef.current;
        let validationErrors = {};
        // @ts-ignore
        const errors = error?.response?.data?.errors?.body;
        errors?.forEach((error: any) => {
          // @ts-ignore
          validationErrors[error.path?.[0]] = error.message;
        });

        // @ts-ignore
        formik.setErrors(validationErrors);
      } catch (err) {
        console.log("e in ", err);
      }
    },
  });

  const createMutation: UseMutationResult<T, Error, any> = useMutation({
    mutationFn: async (data: T) =>
      createService({
        data,
      }),
    onSuccess: async (entity) => {
      await queryClient.invalidateQueries(["list", entityType]);
      // @ts-ignore
      if (onEntityCreated) onEntityCreated(entity.id);
    },
    onError: (error) => {
      try {
        if (
          // @ts-ignore
          error.response.status === 409 &&
          entityType === "OrganizationSettings"
        ) {
          enqueueSnackbar(`Diese Subdomain ist bereits vergeben`, {
            variant: "error",
          });
          return;
        }

        const formik = formRef.current;
        let validationErrors = {};
        // @ts-ignore
        const errors = error?.response?.data?.errors?.body;
        errors?.forEach((error: any) => {
          // @ts-ignore
          validationErrors[error.path?.[0]] = error.message;
        });

        // @ts-ignore
        formik.setErrors(validationErrors);
      } catch (err) {
        //
      }
    },
  });

  const update = async (data: T) => {
    const changedValues = getChangedValues(data, query.data);

    if (Object.keys(changedValues).length) {
      return updateMutation.mutateAsync(changedValues);
    }
    // no changes, so we don't need to update. Send Location back,
    // since it's expected
    return new Promise((resolve) => resolve(data));
  };

  if (!isNew && !query.data) {
    return <Loading />;
  }

  if (!isNew && query.isError) {
    return (
      <Alert severity={"error"}>
        <AlertTitle>Fehler</AlertTitle>
        {entityType} konnte nicht geladen werden: {query.error.message}
      </Alert>
    );
  }

  // const a: FormikErrors<User> = {
  //   email: "test",
  // }

  return (
    // @ts-ignore
    <Formik<T>
      // @ts-ignore
      innerRef={formRef}
      initialValues={query.data || initialData}
      // @ts-ignore
      onSubmit={async (values: T) => {
        if (isNew) {
          return createMutation.mutateAsync(values);
        }
        return await update(values);
      }}
      enableReinitialize
    >
      {/*@ts-ignore*/}
      <Form>{children({ update, create: createMutation })}</Form>
    </Formik>
  );
};
