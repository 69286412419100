import { FastField, FastFieldProps, Field } from "formik";
import { FieldProps } from "formik/dist/Field";
import { GenericFieldHTMLAttributes } from "formik/dist/types";
import React from "react";
import { FastFieldAttributes } from "formik/dist/FastField";
import {
  FieldInputProps,
  FormikComputedProps,
  FormikHandlers,
  FormikHelpers,
  FormikRegistration,
  FormikSharedConfig,
  FormikState,
} from "formik";

const { JSONPath } = require("jsonpath-plus");

export type ComplexFieldProps = {
  name: string;
  children: (props: {
    field: FieldInputProps<any>;
    form: FormikSharedConfig<{}> &
      FormikState<any> &
      FormikHelpers<any> &
      FormikHandlers &
      FormikComputedProps<any> &
      FormikRegistration & { submitForm: () => Promise<any> };
    disabled: boolean;
  }) => React.ReactNode;
  isDynamic: boolean;
  disabled: boolean;
};

const shouldUpdate = (
  nextProps: FastFieldAttributes<any>,
  current: FastFieldAttributes<any>,
  fieldName: string
) => {
  console.log(
    fieldName,
    "should update",
    nextProps.disabled !== current.disabled ||
      JSONPath({ json: nextProps.formik.values, path: fieldName }) !==
        JSONPath({ json: current.formik.values, path: fieldName })
  );
  return (
    nextProps.disabled !== current.disabled ||
    JSONPath({ json: nextProps.formik.values, path: fieldName }) !==
      JSONPath({ json: current.formik.values, path: fieldName })
  );
};

export const ComplexField = (
  props: ComplexFieldProps
): ReturnType<typeof Field> => {
  const { name, children, isDynamic, disabled, ...rest } = props;
  if (isDynamic) {
    return (
      <Field name={`${name}`} disabled={disabled} props={rest}>
        {({ field, form }: FieldProps) =>
          children({ ...rest, field, form, disabled })
        }
      </Field>
    );
  }

  return (
    <FastField
      name={`${name}`}
      shouldUpdate={(
        nextProps: FastFieldProps & GenericFieldHTMLAttributes,
        current: FastFieldProps
      ): boolean => shouldUpdate(nextProps, current, name)}
      disabled={disabled}
    >
      {({ field, form }: FieldProps) =>
        children({ ...rest, field, form, disabled })
      }
    </FastField>
  );
};
