import React from "react";
import { useTheme } from "@mui/material/styles";
// @ts-ignore
import { useConfig } from "base-shell/lib/providers/Config";
// @ts-ignore
import { useOnline } from "base-shell/lib/providers/Online";
// @ts-ignore
import { useTheme as useAppTheme } from "material-ui-shell/lib/providers/Theme";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  AppBar,
  Box,
  LinearProgress,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
// @ts-ignore
import { useMenu } from "material-ui-shell/lib/providers/Menu";
import Navbar from "./Layout/Navbar";
import { TabItem } from "../core/types";

export type PageProps = {
  children: React.ReactNode | ((TabNav: () => JSX.Element) => React.ReactNode);
  isLoading?: boolean;
  onBackClick?: any;
  appBarContent?: any;
  contentStyle?: any;
  tabs?: TabItem[];
};

function PageReworked({
  children,
  onBackClick,
  isLoading,
  appBarContent = null,
  contentStyle,
  tabs,
}: PageProps) {
  const { isRTL } = useAppTheme();
  const isOnline = useOnline();
  const theme = useTheme();
  const { appConfig } = useConfig();
  const { menu } = appConfig || {};
  const { width = 240 } = menu || {};
  const menuContext = useMenu();

  const { toggleThis, isMenuOpen, isDesktop } = menuContext || {};
  const intl = useIntl();
  const navigate = useNavigate();

  const { id } = useParams();

  const handleDrawerMenuClick = () => {
    if (!isMenuOpen) {
      toggleThis("isMiniMode", false);
      toggleThis("isMenuOpen", true);
      if (!isDesktop) {
        toggleThis("isMobileMenuOpen");
      }
    } else {
      toggleThis("isMobileMenuOpen");
    }
  };

  const TabNav = () => (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={window.location.pathname}
        onChange={(e, t) => navigate(t)}
        aria-label="simple tabs example"
        textColor="inherit"
        indicatorColor="secondary"
        sx={{
          display: tabs && tabs.length ? "flex" : "none",

          "& .MuiButtonBase-root ": {
            padding: "0px 0",
          },
        }}
      >
        {tabs &&
          tabs.map((tab) => (
            <Tab
              key={tab.label + "-key"}
              label={
                <Typography
                  variant={"h2"}
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.95rem",
                    lineHeight: 1.71,
                    letterSpacing: "0.03938em",
                    margin: "0 0 0 0",
                    ".MuiButtonBase-root": {
                      padding: "8px 0",
                    },
                  }}
                >
                  {tab.label}
                </Typography>
              }
              value={id ? tab.value.replace(":id", id) : tab.value}
              sx={
                {
                  // textTransform: "none",
                }
              }
            />
          ))}
      </Tabs>
    </Box>
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
        padding: "0 1.5rem",
      }}
    >
      <AppBar
        position={isDesktop ? "absolute" : undefined}
        sx={{
          width:
            isMenuOpen && isDesktop ? `calc(100% - ${width}px)` : undefined,
          zIndex: theme.zIndex["appBar"],
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          maxHeight: 64,
          marginLeft: isRTL ? 0 : -12,
          marginRight: isRTL ? 30 : 0,
        }}
      >
        <Navbar />
      </AppBar>
      <div
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          //...theme.mixins.toolbar,
          minHeight: 64, //height of AppBar
        }}
      />

      {/*navigation tabs */}
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          paddingBottom: "1rem",
          maxWidth: "calc(100vw - 240px)",
          ...contentStyle,
        }}
      >
        <Stack direction="column" height={"100%"}>
          {/*offline indicator*/}
          {isLoading && <LinearProgress />}
          {!isOnline && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: 15,
                backgroundColor: theme.palette.secondary.main,
              }}
            >
              <Typography variant="caption" color="textSecondary" noWrap>
                {intl.formatMessage({
                  id: "offline",
                  defaultMessage: "Offline",
                })}
              </Typography>
            </div>
          )}

          {typeof children === "function" ? children(TabNav) : children}
        </Stack>
      </div>
    </div>
  );
}

export default PageReworked;
