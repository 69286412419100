import Grid2 from "@mui/material/Unstable_Grid2";
import { useFormikContext } from "formik";
import { Organization } from "shared-ts";
import { XButton, FormikField, parseString } from "shared";
import { routePaths } from "../../../config/routePaths";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import React from "react";

export const OrganizationForm = () => {
  const formik = useFormikContext<Organization["settings"]["general"]>();
  const navigate = useNavigate();
  const isNew = useParams().id === "new";
  const [changedSubdomain, setChangedSubdomain] = React.useState(false);

  const { values } = formik;

  useEffect(() => {
    if (!changedSubdomain && isNew) {
      formik.setFieldValue("subdomain", parseString(values.displayName));
    }
  }, [changedSubdomain, values.displayName]);

  useEffect(() => {
    if (!formik.errors["subdomain"]) {
      // @ts-ignore
      formik.setFieldError("subdomain", formik.errors.settings);
    }
  }, [formik.errors]);

  return (
    <Grid2 container xs={12} rowSpacing={2}>
      <Grid2 xs={12}>
        <FormikField
          type={"text"}
          name={"displayName"}
          label={"Name"}
          isDynamic
        />
      </Grid2>

      <Grid2 xs={12}>
        <FormikField
          type={"text"}
          name={"subdomain"}
          label={"Subdomain"}
          onChange={() => setChangedSubdomain(true)}
          // @ts-ignore
          // value={values["general.subdomain"]}
          disabled={!isNew}
          isDynamic
        />
      </Grid2>

      <Grid2 xs={12} display={"flex"} justifyContent={"space-between"}>
        <XButton
          variant={"outlined"}
          type={"button"}
          onClick={() => navigate(routePaths.organizations)}
        >
          Abbrechen
        </XButton>
        <XButton
          variant={"contained"}
          color={"primary"}
          type={"submit"}
          disabled={!formik.dirty}
        >
          Speichern
        </XButton>
      </Grid2>
    </Grid2>
  );
};
