import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AuthState, Organization } from "../types";
import _ from "lodash";

export type Store = {
  auth: AuthState;
  setAuth: Function;

  pulseId?: number;
  setPulseId: Function;

  currentProjectId: string | null;
  setCurrentProjectId: Function;

  settings: Organization["settings"];
  setSettings: Function;

  logout: () => void;
};

export const useStore = create<Store>()(
  devtools(
    persist(
      (set, get) => ({
        auth: {
          user: null,
          isRefreshing: false,
        },
        setAuth: (newAuthState: AuthState) =>
          set(() => {
            // setLocalStorage("auth", newAuthState);
            return { auth: newAuthState };
          }),

        pulseId: undefined,
        setPulseId: (newPulseId: number) =>
          set(() => {
            // setLocalStorage("pulse", newPulse);
            return { pulseId: newPulseId };
          }),

        currentProjectId: null,
        setCurrentProjectId: (newCurrentProjectId: string) =>
          set(() => {
            // setLocalStorage("currentProjectId", newCurrentProjectId);
            return { currentProjectId: newCurrentProjectId };
          }),

        settings: null,
        setSettings: (newSettings: Organization["settings"]) =>
          set(() => {
            // setLocalStorage("settings", newSettings);
            console.log("newSettings", newSettings);
            return { settings: newSettings };
          }),

        logout: () => {
          // @ts-ignore
          const clear = useStore.persist.clearStorage;

          set(
            // @ts-ignore
            (state) =>
              _.omit(state, [
                "auth.user",
                "currentProjectId",
                "pulse.availableProjects",
              ]),
            true
          );
        },

        // reset: () => set((state) => _.omit(state, ["user"]), true),
      }),
      {
        name: "React App",
        // omit the following keys from persist
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => !["pulse"].includes(key))
          ),
      }
    ),
    { name: "Postspot Store" }
  )
);
