import { User } from "../../types";
import axios from "axios";

// vars
// @ts-ignore
const apiOrigin = process.env.REACT_APP_API_ORIGIN;

export class AuthService {
  static login(
    email: string,
    password: string
  ): Promise<{ data?: { user: User; token: string } }> {
    return axios({
      method: "POST",
      url: `${apiOrigin}/v1/auth/login`,
      data: { email, password },
    });
  }
}
