import { request } from "../request";
import { Organization } from "shared-ts";
import { vars } from "../../../config/vars";
import { PaginatedResponse } from "../types";

export class OrganizationService {
  static createOrganization({
    organization,
  }: {
    organization: Organization;
  }): Promise<Organization> {
    return request({
      method: "POST",
      url: `${vars.apiOrigin}/v1/organizations/`,
      data: organization,
    });
  }

  static listOrganizations(): Promise<PaginatedResponse<Organization>> {
    return request({
      method: "GET",
      url: `${vars.apiOrigin}/v1/organizations/`,
    });
  }

  static getOrganization({ id }: { id: string }): Promise<Organization> {
    return request({
      method: "GET",
      url: `${vars.apiOrigin}/v1/organizations/${id}`,
    });
  }

  static getOrganizationSettings({
    id,
  }: {
    id: string;
  }): Promise<Organization["settings"]> {
    return request({
      method: "GET",
      url: `${vars.apiOrigin}/v1/organizations/${id}/settings`,
    });
  }

  static updateOrgnizationSettings({
    id,
    data,
  }: {
    id: string;
    data: Partial<Organization["settings"]>;
  }): Promise<Organization> {
    return request({
      method: "PATCH",
      url: `${vars.apiOrigin}/v1/organizations/${id}/settings`,
      data,
    });
  }
}
