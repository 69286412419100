import { GridColDef } from "@mui/x-data-grid";
import { Organization } from "shared-ts";
import { XButton } from "shared";
import { vars } from "../../../config/vars";

export const organizationDefaultColumns: GridColDef<Organization>[] = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
    hide: true,
  },
  {
    field: "displayName",
    headerName: "Name",
    width: 200,
    flex: 1,
  },
  {
    field: "subdomain",
    headerName: "Subdomain",
    width: 200,
    flex: 1,
  },
  {
    field: "posts",
    headerName: "Anzahl Posts",
    width: 200,
    flex: 1,
    valueGetter: (params) => params.row.posts?.length,
  },
  {
    field: "createdAt",
    headerName: "Erstellt am",
    width: 200,
    flex: 1,
    valueFormatter: (params) =>
      new Date(params.value as string).toLocaleDateString(),
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 200,
    flex: 1,
    renderCell: (params) => {
      return (
        <XButton
          variant="outlined"
          color="secondary"
          onClick={() => {
            // open in new tab
            window.open(
              `${vars.desktopAppUrl}/login?organizationId=${params.row.id}`
            );
          }}
        >
          Login
        </XButton>
      );
    },
  },
];
