import { useNavigate, useParams } from "react-router-dom";
import { GeneralDataProvider } from "../../components/GeneralDataProvider";
import { UserService } from "../../core/api/services/user.service";
import { User } from "shared-ts";
import { UserForm } from "./components/UserForm";
import PageReworked from "../../components/PageReworked";
import React from "react";
import { Stack } from "@mui/material";
import { PageHeader } from "shared";
import { initialUserData } from "./components/initialUserData";
import { useQueryClient } from "@tanstack/react-query";

export const UserDetailPage = () => {
  const id = useParams().id as string;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const isNew = id === "new";

  return (
    <PageReworked>
      <Stack spacing={2}>
        <PageHeader
          title={isNew ? "Benutzer anlegen" : "Benutzer bearbeiten"}
        />
        <GeneralDataProvider<User>
          id={id}
          // @ts-ignore
          initialData={initialUserData}
          entityType={"User"}
          getService={UserService.getUser}
          updateService={({ id, data }: { id: string; data: Partial<User> }) =>
            UserService.updateUser({
              id: id,
              user: data,
            })
          }
          createService={async ({ data }: { data: User }) => {
            const user = await UserService.createUser({ user: data });
            queryClient.invalidateQueries(["get", "User"]);
            navigate(`/users`);
            return user;
          }}
        >
          {() => <UserForm />}
        </GeneralDataProvider>
      </Stack>
    </PageReworked>
  );
};
