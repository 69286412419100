import { request } from "../request";
import { vars } from "../../../config/vars";
import { User } from "../../types";
import { PaginatedResponse } from "../types";

export class UserService {
  static createUser({ user }: { user: Partial<User> }): Promise<User> {
    return request({
      method: "POST",
      url: `${vars.apiOrigin}/v1/users`,
      data: user,
    });
  }

  static getUser({ id }: { id: string }): Promise<User> {
    return request({
      method: "GET",
      url: `${vars.apiOrigin}/v1/users/${id}`,
    });
  }

  static listUsers({
    filter,
  }: {
    filter?: object;
  }): Promise<PaginatedResponse<User>> {
    return request({
      method: "GET",
      url: `${vars.apiOrigin}/v1/users/`,
      query: {
        filter,
      },
    });
  }

  static updateUser({
    id,
    user,
  }: {
    id: string;
    user: Partial<User>;
  }): Promise<User> {
    return request({
      method: "PATCH",
      url: `${vars.apiOrigin}/v1/users/${id}`,
      data: user,
    });
  }

  static getChatwootSSO({ id }: { id: string }): Promise<{ url: string }> {
    return request({
      method: "GET",
      url: `${vars.apiOrigin}/v1/users/${id}/chatwoot/sso`,
    });
  }
}
