import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { Alert } from "@mui/lab";
import {
  AlertTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Loading } from "./Loading";
import { PaginatedResponse } from "shared-ts";

type GenericSelectorProps<T> = {
  entityType: string;
  name: string;
  label: string;
  listService: ({
    page,
    size,
    filter,
  }: {
    page?: number | undefined;
    size?: number | undefined;
    filter?: string | undefined;
  }) => Promise<PaginatedResponse<T>>;
  labelKey?: string;
  disabled?: boolean;
};
export const GenericSelector = <T extends unknown>({
  name,
  label,
  entityType,
  listService,
  labelKey = "name",
  disabled = false,
}: GenericSelectorProps<T>) => {
  const formik = useFormikContext();

  const { values, setFieldValue } = formik;

  const query: UseQueryResult<PaginatedResponse<T>> = useQuery(
    ["get", entityType],
    () =>
      listService({
        page: 1,
        size: 100,
      })
  );

  const options =
    query.data?.data?.map((instance) => ({
      // @ts-ignore
      label: instance[labelKey],
      // @ts-ignore
      value: instance.id,
    })) || [];

  if (!query.data) {
    return (
      <Loading
        sx={{
          minHeight: "56px",
        }}
      />
    );
  }

  if (query.isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Fehler</AlertTitle>
        {entityType} Daten konnten nicht geladen werden
      </Alert>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-select-label`}
        id={`${name}-select`}
        // @ts-ignore
        value={values[name]}
        label={label}
        onChange={async (event) => {
          const newId = event.target.value;
          await setFieldValue(name, newId);
        }}
        renderValue={(value) => {
          const option = options.find((option) => option.value === value);
          return option?.label;
        }}
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
