import { isEqual } from "lodash";

export const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    let hasChanged;
    if (typeof initialValues[key] === "object") {
      hasChanged = !isEqual(initialValues[key], value);
    } else {
      hasChanged = initialValues[key] !== value;
    }

    if (hasChanged) {
      // @ts-ignore
      acc[key] = value;
    }

    return acc;
  }, {});
};
