import { useNavigate, useParams } from "react-router-dom";
import { GeneralDataProvider } from "../../components/GeneralDataProvider";
import { Organization } from "shared-ts";
import PageReworked from "../../components/PageReworked";
import React from "react";
import { Stack } from "@mui/material";
import { DataGridContainer, PageHeader } from "shared";
import { OrganizationService } from "../../core/api/services/OrganizationService";
import { OrganizationForm } from "./components/OrganizationForm";
import { UserService } from "../../core/api/services/user.service";
import { userDefaultColumns } from "../../core/api/DataGridColumns/userDefaultColumns";

export const OrganizationDetailPage = () => {
  const id = useParams().id as string;
  const navigate = useNavigate();

  const isNew = id === "new";

  return (
    <PageReworked>
      <Stack spacing={2}>
        <PageHeader
          title={isNew ? "Organisation anlegen" : "Organisation bearbeiten"}
        />
        <GeneralDataProvider<Organization["settings"]["general"]>
          id={id}
          entityType={"OrganizationSettings"}
          initialData={{
            displayName: "",
            subdomain: "",
          }}
          onEntityCreated={(id: string) => navigate(`/organizations`)}
          getService={async ({ id }) => {
            const res = await OrganizationService.getOrganizationSettings({
              id,
            });

            return res?.general;
          }}
          // @ts-ignore
          updateService={({
            id,
            data,
          }: {
            id: string;
            data: Organization["settings"]["general"];
          }) =>
            OrganizationService.updateOrgnizationSettings({
              id,
              data: {
                general: data,
              },
            })
          }
          // @ts-ignore
          createService={({
            data,
          }: {
            data: Organization["settings"]["general"];
          }) =>
            OrganizationService.createOrganization({
              // @ts-ignore
              organization: {
                settings: {
                  general: data,
                },
              },
            })
          }
        >
          {/*@ts-ignore*/}
          {() => <OrganizationForm />}
        </GeneralDataProvider>
        {!isNew && (
          <DataGridContainer
            queryHook={UserService.listUsers}
            queryHookParams={{}}
            defaultColumns={userDefaultColumns}
            onRowClick={(row) => navigate(`/users/${row.id}`)}
            filter={{
              organizationId: {
                $eq: id,
              },
            }}
          />
        )}
      </Stack>
    </PageReworked>
  );
};
