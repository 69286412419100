import React, { lazy } from "react";
import { routePaths } from "./routePaths";
import { OrganizationsListPage } from "../pages/Organizations/OrganizationsListPage";
import { UserListPage } from "../pages/Users/UserListPage";
import { UserDetailPage } from "../pages/Users/UserDetailPage";
import { OrganizationDetailPage } from "../pages/Organizations/OrganizationDetailPage";

const PrivateRoute = lazy(
  () => import("../components/Authentication/AuthorizedRoute")
);

const routes = [
  {
    path: routePaths.home,
    exact: true,
    element: (
      <PrivateRoute>
        <></>
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.organizations,
    exact: true,
    element: (
      <PrivateRoute>
        <OrganizationsListPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.organization(":id"),
    exact: false,
    element: (
      <PrivateRoute>
        <OrganizationDetailPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.users,
    exact: true,
    element: (
      <PrivateRoute>
        <UserListPage />
      </PrivateRoute>
    ),
  },
  {
    path: routePaths.user(":id"),
    exact: false,
    element: (
      <PrivateRoute>
        <UserDetailPage />
      </PrivateRoute>
    ),
  },
];

export default routes;
