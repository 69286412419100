import red from "@mui/material/colors/red";
import green from "@mui/material/colors/green";
import pink from "@mui/material/colors/pink";
import shadows, { customShadows } from "./shadows";
import palette from "./palette";
import { darken, lighten } from "@mui/material";

export type XTheme = {
  palette: typeof palette;
  shadows: typeof shadows;
  customShadows: typeof customShadows;
  typography: any;
  dimensions: {
    drawerWidth: number;
    formWidth: string;
  };
  spacing: any;
};

const themes = [
  {
    id: "default",
    source: {
      palette: {
        ...palette,
        primary: {
          main: "#759a60",
          veryLight: lighten("#759a60", 0.4),
          dark: darken("#759a60", 0.1),
        },
        complementary: {
          main: "#85609A",
          contrastText: "#fff",
        },
        secondary: {
          main: "#60929A",
          veryLight: lighten("#60929A", 0.3),
        },
        tertiary: {
          main: "#85609A",
          veryLight: lighten("#85609A", 0.3),
        },
        quaternary: {
          main: "#9A6860",
          veryLight: lighten("#9A6860", 0.3),
        },
        success: {
          main: green[500],
          veryLight: lighten(green[500], 0.4),
        },
        error: {
          main: red[500],
          veryLight: lighten(red[500], 0.4),
        },
      },
      dimensions: {
        drawerWidth: 256,
        formWidth: 800,
      },
      shadows,
      customShadows,
      typography: {
        h6: {
          fontWeight: 100,
        },
        h1: {
          fontSize: "1.8rem",
          fontWeight: 500,
          lineHeight: 1.167,
          letterSpacing: "-0.01562em",
        },
        h2: {
          fontSize: "26pt",
          fontWeight: 300,
          lineHeight: 1.2,
          letterSpacing: "-0.00833em",
        },
        h3: {
          fontSize: "18pt",
          fontWeight: 400,
          letterSpacing: "0.00735em",
          color: "hsl(0, 0%, 28%)",
        },
        h4: {
          fontSize: "20pt",
          fontWeight: 400,
          lineHeight: 1.235,
          letterSpacing: "0.00735em",
        },
      },
      zIndex: {
        mobileStepper: 1000000,
        fab: 1050000,
        speedDial: 1050000,
        appBar: 1100000,
        drawer: 1200000,
        modal: 1300000,
        // display over menu
        snackbar: 1400000,
        tooltip: 1500000,
      },
    },
  } as unknown as XTheme,
  {
    id: "red",
    color: red[500],
    source: {
      palette: {
        primary: red,
        secondary: pink,
        error: red,
      },
    },
  },
  {
    id: "green",
    color: green[500],
    source: {
      palette: {
        primary: green,
        secondary: red,
        error: red,
      },
    },
  },
];

export default themes;
