import React, { SyntheticEvent, useState } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { GridColumns } from "@mui/x-data-grid";
import { PaginatedResponse } from "shared-ts";
import {
  Box,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { StyledDataGrid } from "./StyledDataGrid";
import SearchIcon from "@mui/icons-material/Search";

export type Filter = {
  [key: string]: {
    $eq?: any;
    $gt?: any;
    $gte?: any;
    $in?: any[];
    $lt?: any;
    $lte?: any;
    $ne?: any;
    $nin?: any[];
  };
};

type FilterPreset = {
  label: string;
  filter: Filter;
};

function a11yProps(index: number) {
  return {
    id: `filter-preset-tab-${index}`,
    "aria-controls": `filter-preset-tabpanel-${index}`,
  };
}

export const DataGridContainer = ({
  objectName,
  queryHook,
  queryHookParams,
  defaultColumns,
  onRowClick,
  filter,
  filterPresets,
  searchEnabled = false,
  gridStyles,
  sortModel,
}: {
  objectName?: string;
  queryHook: (query: any) => Promise<PaginatedResponse<any>>;
  queryHookParams: object;
  defaultColumns: GridColumns;
  onRowClick?: (row: any) => void;
  filter?: Filter;
  filterPresets?: FilterPreset[];
  searchEnabled?: boolean;
  gridStyles?: any;
  sortModel?: any;
}) => {
  const [pageNumber] = useState(1);
  const [pageSize] = useState(50);
  const [selectedFilterPresets, setSelectedFilterPresets] = useState(0);
  const [search, setSearch] = useState("");

  const combinedFilter = {
    ...filter,
    ...(filterPresets ? filterPresets[selectedFilterPresets].filter : {}),
  };

  const {
    isLoading,
    error,
    data,
  }: UseQueryResult<PaginatedResponse<any>, Error> = useQuery(
    [
      "list",
      objectName,
      {
        ...queryHookParams,
        filter: combinedFilter,
        sort: sortModel,
        search,
        pageNumber,
        pageSize,
      },
    ],
    () =>
      queryHook({
        ...queryHookParams,
        filter: combinedFilter,
        sort: sortModel,
        ...(search ? { search } : {}),
        pageNumber,
        pageSize,
      })
  );

  const handleFilterPresetChange = (
    event: SyntheticEvent,
    newValue: number
  ) => {
    setSelectedFilterPresets(newValue);
  };

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
      elevation={3}
    >
      {/*filter presets */}
      {filterPresets && (
        <Tabs
          value={selectedFilterPresets}
          onChange={handleFilterPresetChange}
          aria-label="Filter Presets"
          sx={{
            flexGrow: 0,
          }}
        >
          {filterPresets.map((preset, index) => (
            <Tab
              key={`tab-preset-${preset.label}`}
              label={preset.label}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      )}

      {/*search*/}
      {searchEnabled && (
        <Box sx={{ width: "100%", padding: "1rem", flexGrow: 0 }}>
          <TextField
            fullWidth
            placeholder="Suche"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}

      {/*Table*/}
      <Box
        sx={{
          height: "100%",
          minHeight: "400px",
          width: "100%",
          backgroundColor: "white",
          flexGrow: 1,
        }}
      >
        <StyledDataGrid
          columns={defaultColumns}
          rows={data?.data || []}
          loading={isLoading}
          hideFooterSelectedRowCount
          componentsProps={{
            pagination: {
              labelRowsPerPage: "Zeilen pro Seite",
            },
          }}
          onRowClick={(params, event) => {
            onRowClick &&
              // @ts-ignore
              event.target.tagName !== "BUTTON" &&
              onRowClick(params);
          }}
          localeText={{
            noRowsLabel: "Es sind noch keine Einträge vorhanden",
            columnHeaderSortIconLabel: "Sortieren",
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                `${from} - ${to} von ${count}`,
            },
            // currently hidden
            // footerRowSelected: (selectedCount) =>
            //   `${selectedCount} Zeile(n) ausgewählt`,
          }}
          sx={gridStyles}
        />
      </Box>
    </Paper>
  );
};
