import { DataGridContainer, XButton } from "shared";
import { UserService } from "../../core/api/services/user.service";
import { userDefaultColumns } from "../../core/api/DataGridColumns/userDefaultColumns";
import Grid2 from "@mui/material/Unstable_Grid2";
import PageReworked from "../../components/PageReworked";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../config/routePaths";

export const UserListPage = () => {
  const navigate = useNavigate();

  return (
    <PageReworked>
      <Grid2 container xs={12} rowSpacing={3}>
        <Grid2 xs={8}>
          <Typography variant="h1">Benutzer</Typography>
        </Grid2>
        <Grid2 xs={4} display={"flex"} justifyContent={"flex-end"}>
          <XButton
            variant={"contained"}
            onClick={() => {
              navigate("/users/new");
            }}
          >
            Neuer Benutzer
          </XButton>
        </Grid2>
        <Grid2 xs={12}>
          <DataGridContainer
            queryHook={UserService.listUsers}
            queryHookParams={{}}
            defaultColumns={userDefaultColumns}
            onRowClick={(row) => navigate(routePaths.user(row.id))}
          />
        </Grid2>
      </Grid2>
    </PageReworked>
  );
};
