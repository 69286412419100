import React, { useEffect } from "react";
import { Website } from "shared-ts";
import styled from "@emotion/styled";

const CookieBannerWrapper: any = styled.div`
  position: fixed;
  left: 0;
  top: ${(props: any) => (props.position === "top" ? "0" : "auto")};
  bottom: ${(props: any) => (props.position === "bottom" ? "0" : "auto")};
  z-index: 9999999999999;

  width: 100%;
  padding: 1rem;
  background-color: ${(props: any) => props.backgroundColor};

  display: ${(props: any) => (props.show ? "flex" : "none")};

  .cookie-banner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4rem;
    width: 100%;

    @media (max-width: 800px) {
      flex-direction: column;
    }

    .right {
      @media (max-width: 800px) {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }
  }

  p {
    color: ${(props: any) => props.color};
  }

  .right {
    display: flex;
    align-items: center;
    gap: 1rem;

    a {
      color: ${(props: any) => props.color};
    }
  }
`;

type CookieBannerProps = {
  color: string;
  backgroundColor: string;
};

const CookieBannerButton = styled.button`
  background-color: ${(props: CookieBannerProps) => props.backgroundColor};
  color: ${(props: CookieBannerProps) => props.color};
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  min-width: 100px;

  &:hover,
  &:focus {
    color: ${(props: CookieBannerProps) => props.backgroundColor} !important;
    background-color: ${(props: CookieBannerProps) =>
      `${props.color} !important`};
  }
`;

export const CookieBanner = ({
  cookieSettings,
  demoMode = false,
}: {
  cookieSettings: Website["settings"]["cookiePlugin"];
  demoMode?: boolean;
}) => {
  return (
    <>
      <CookieBannerWrapper
        id="cookies-eu-banner"
        position={cookieSettings.position}
        // palette
        backgroundColor={cookieSettings.palette.background}
        color={cookieSettings.palette.primary}
        buttonBackgroundColor={cookieSettings.palette.buttonBackground}
        buttonColor={cookieSettings.palette.button}
        show={demoMode}
      >
        <div className={"cookie-banner-container"}>
          {/*left*/}
          <p
            style={{
              width: "auto",
            }}
          >
            {cookieSettings.text.message}
          </p>

          {/*right*/}
          <div className={"right"}>
            <a href={cookieSettings.policyUrl} id="cookies-eu-more">
              {cookieSettings.text.policyButton}
            </a>
            <CookieBannerButton
              id="cookies-eu-reject"
              color={cookieSettings.palette.button}
              backgroundColor={cookieSettings.palette.buttonBackground}
            >
              {cookieSettings.text.declineButton}
            </CookieBannerButton>
            <CookieBannerButton
              id="cookies-eu-accept"
              color={cookieSettings.palette.button}
              backgroundColor={cookieSettings.palette.buttonBackground}
            >
              {cookieSettings.text.acceptButton}
            </CookieBannerButton>
          </div>
        </div>
      </CookieBannerWrapper>
    </>
  );
};
