import { GridColDef } from "@mui/x-data-grid";
import { User } from "shared-ts";

export const userDefaultColumns: GridColDef<User>[] = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    flex: 1,
  },
  {
    field: "email",
    headerName: "E-Mail",
    width: 200,
    flex: 1,
  },
  {
    field: "role",
    headerName: "Rolle",
    width: 200,
    flex: 1,
  },
  {
    field: "createdAt",
    headerName: "Erstellt am",
    width: 200,
    flex: 1,
    valueFormatter: (params) =>
      new Date(params.value as string).toLocaleDateString(),
  },
];
