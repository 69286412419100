export const userRoleMapping: {
  [key: string]: {
    label: string;
    value: string;
  };
} = {
  user: {
    label: "User",
    value: "USER",
  },
  admin: {
    label: "Admin",
    value: "ADMIN",
  },
};
