import React from "react";
import { Stack, Typography } from "@mui/material";
import { XButton } from "./XButton";
import { useNavigate } from "react-router-dom";

export const PageHeader = ({
  title,
  buttonText,
  buttonFunc,
}: {
  title: string;
  buttonText?: string;
  buttonFunc?: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
      {buttonText ? (
        <XButton variant={"contained"} onClick={buttonFunc}>
          {buttonText}
        </XButton>
      ) : (
        ""
      )}
    </Stack>
  );
};
