import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "./auth";
import { reject } from "lodash";

export type ApiRequestOptions = {
  readonly method: "GET" | "POST" | "PATCH" | "DELETE";
  readonly url: string;
  readonly query?: Record<string, any>;
  readonly data?: any;
};

const isDefined = <T>(
  value: T | null | undefined
): value is Exclude<T, null | undefined> => {
  return value !== undefined && value !== null;
};

const getHeaders = async (): Promise<Record<string, string>> => {
  const headers = Object.entries({
    Accept: "application/json",
  })
    .filter(([_, value]) => isDefined(value))
    .reduce(
      (headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
      }),
      {} as Record<string, string>
    );

  headers["Authorization"] = `Bearer ${await getToken()}`;

  return headers;
};

const getQueryString = (params: Record<string, any>): string => {
  const qs: string[] = [];

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v);
        });
      } else if (typeof value === "object") {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v);
        });
      } else {
        append(key, value);
      }
    }
  };

  Object.entries(params).forEach(([key, value]) => {
    process(key, value);
  });

  if (qs.length > 0) {
    return `?${qs.join("&")}`;
  }

  return "";
};

const getUrl = (options: ApiRequestOptions): string => {
  const encoder = encodeURI;

  const { url } = options;

  if (options.query) {
    return `${url}${getQueryString(options.query)}`;
  }
  return url;
};

export class ApiError extends Error {
  public readonly url: string;
  public readonly status: number;
  public readonly statusText: string;
  public readonly body: any;
  public readonly request: ApiRequestOptions;

  constructor(
    request: ApiRequestOptions,
    response: ApiResult,
    message: string
  ) {
    super(message);

    this.name = "ApiError";
    this.url = response.url;
    this.status = response.status;
    this.statusText = response.statusText;
    this.body = response.body;
    this.request = request;
  }
}

/* eslint-disable */
export type ApiResult = {
  readonly url: string;
  readonly ok: boolean;
  readonly status: number;
  readonly statusText: string;
  readonly body: any;
};

const catchErrorCodes = (
  options: ApiRequestOptions,
  result: ApiResult
): void => {
  console.log("catchErrorCodes", options, result);
  const errors: Record<number, string> = {
    400: "Bad Request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
    500: "Internal Server Error",
    502: "Bad Gateway",
    503: "Service Unavailable",
    // @ts-ignore
    ...options.errors,
  };

  const error = errors[result.status];

  console.log("error", error);
  if (error) {
    throw new ApiError(options, result, error);
  }

  if (!result.ok) {
    throw new ApiError(options, result, "Generic Error");
  }
};

const getQueryString2 = (params: Record<string, any>): string => {
  const qs: string[] = [];

  const append = (key: string, value: any) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const process = (key: string, value: any) => {
    if (isDefined(value)) {
      if (Array.isArray(value)) {
        value.forEach((v) => {
          process(key, v);
        });
      } else if (typeof value === "object") {
        Object.entries(value).forEach(([k, v]) => {
          process(`${key}[${k}]`, v);
        });
      } else {
        append(key, value);
      }
    }
  };

  Object.entries(params).forEach(([key, value]) => {
    process(key, value);
  });

  if (qs.length > 0) {
    return `?${qs.join("&")}`;
  }

  return "";
};

export async function request(options: ApiRequestOptions): Promise<any> {
  const { method, query, data } = options;
  const url = getUrl(options);
  const headers = await getHeaders();

  const queryString = getQueryString(query || {});

  const requestConfig: AxiosRequestConfig = {
    url,
    headers,
    data,
    method,
    // @ts-ignore
    queryString,
  };

  /*
   * axios result consists of:
   * data: the actual data
   * status: the status code
   * statusText: the status text
   * headers: the response headers
   * config: the request config
   * request: the request object
   *
   * if later on we need more information from the response,
   * we can add a axiosResponse field to the result or something like that
   *
   * returning everything would result in having access the data as response.data.data.data
   * (axios, react-query, data field for paginated queries) / 2x data for non paginated queries
   * this would result in worse readability
   */

  const response = await axios.request(requestConfig);

  return response.data;
}
