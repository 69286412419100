import { lazy } from "react";
import locales from "./locales";
import themes from "./themes/themes";
import { Loading } from "shared";
import routes from "./routes";

const config = {
  containers: {
    LayoutContainer: lazy(() =>
      import("../components/LayoutContainer/LayoutContent")
    ),
  },
  components: {
    Loading,
    Menu: lazy(() => import("../components/Menu/Menu")),
  },
  auth: {
    // signInURL: "/signin",
  },
  pwa: {
    useiOSPWAPrompt: true,
    iOSPWAPromptProps: {},
  },
  routes,
  locale: {
    locales,
    defaultLocale: "de",
    onError: (e) => {
      // Here we warn the user about translation error
      //console.warn(e)
      return;
    },
  },
  menu: {
    width: 240,
    offlineIndicatorHeight: 12,
    initialAuthMenuOpen: false,
    initialMiniMode: false,
    initialMenuOpen: true,
    initialMobileMenuOpen: false,
    initialMiniSwitchVisibility: true,
    MenuHeader: lazy(() => import("../components/Menu/MenuHeader")),
    MenuContent: lazy(() => import("../components/Menu/MenuContent")),
    useWindowWatcher: false,
  },
  theme: {
    themes,
    defaultThemeID: "default",
    defaultIsDarkMode: false,
    defaultIsRTL: false, //change this to true for default Right to Left Language support
  },
  pages: {
    PageNotFound: lazy(() => import("../pages/PageNotFound/PageNotFound")),
  },
};

export default config;
