import axios from "axios";
import { routesConstants } from "../../LEGACY/constants/routesConstants";
import * as Sentry from "@sentry/react";
import { useStore } from "../zustand/store";
import { AuthService } from "./services/AuthService";
import { User } from "../types";
import omit from "lodash/omit";

export const isBrowser = () => typeof window !== "undefined";

export const useLogin = () => {
  const setAuth = useStore((state) => state.setAuth);

  return ({
    email,
    password,
    handleAuthError,
  }: {
    email: string;
    password: string;
    handleAuthError: any;
  }) => {
    return new Promise((resolve, reject) => {
      AuthService.login(email, password)
        .then((result) => {
          if (!result.data) {
            // if request successful, this should not happen
            const error = new Error("No data returned from login");
            Sentry.captureException(error);
            throw error;
          }
          setAuth({
            isAuthenticated: true,
            user: Object.assign({}, result.data.user, {
              token: result.data.token,
            }),
          });

          // toggleThis("isAuthMenuOpen", false);
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          handleAuthError(err);
          Sentry.captureException(err);
          reject(err);
        });
    });
  };
};

export function isTokenExpired() {
  const user = useStore.getState().auth.user;

  if (!user) {
    throw new Error("Kein Nutzer ist eingeloggt");
  }

  // get token
  const token = user.token.accessToken;

  // extract token
  const tokenParts = token.split(".");
  // const header = JSON.parse(atob(tokenParts[0]));
  const body = JSON.parse(atob(tokenParts[1]));
  // typ should be "JWT", alg should be "HS256
  // const { typ, alg } = header;
  // exp = expiry, iat = issuad at, sub = user id
  const { exp } = body;

  // exp is in seconds, timestamp is in ms
  return 1000 * exp - Date.now() < 0;
}

export async function refreshToken() {
  const logout = useStore.destroy;
  const isRefreshing = useStore.getState().auth.isRefreshing;
  const setIsRefreshing = (newRefreshingState: boolean) =>
    useStore.setState((state) => ({
      ...state,
      auth: { ...state.auth, isRefreshing: newRefreshingState },
    }));

  if (isRefreshing) {
    throw new Error("Already refreshing");
  }

  setIsRefreshing(true);

  const user = useStore.getState().auth.user;
  let setUser = (newUser: User) =>
    useStore.setState((state) => ({
      ...state,
      auth: { ...state.auth, user: newUser },
    }));

  if (!user) {
    const error = new Error(
      "tried to refresh token, but user was not logged in"
    );
    Sentry.captureException(error);
    setIsRefreshing(false);
    throw error;
  }

  let refreshResult;
  try {
    refreshResult = await axios.post(routesConstants.api.refreshToken, {
      email: user.email,
      refreshToken: user.token.refreshToken,
    });
    useStore.setState((state) => ({
      ...state,
      auth: { ...state.auth, isRefreshing: false },
    }));
    setUser(
      Object.assign({}, user, {
        token: refreshResult.data,
      })
    );
    setIsRefreshing(false);
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    setIsRefreshing(false);
    useStore.persist.clearStorage();
    // @ts-ignore
    useStore.setState((state) => omit(state, ["auth.user"]));
    throw err;
  }
}

export async function getToken() {
  if (isTokenExpired()) {
    await refreshToken();
  }

  const user = useStore.getState().auth.user;
  if (!user) {
    throw new Error("Kein Nutzer ist eingeloggt");
  }
  return user.token.accessToken;
}

// makes an API request and refreshes the jwt token if necessary
/**
 *
 * @param callback the function to call after refreshing the token
 */
export function refreshAndCall(callback: any): Promise<any> {
  return callback();
  // return isTokenExpired()
  //   ? refreshToken()
  //       .then((result) => {
  //         if (result.success) {
  //           return callback();
  //         } else if (result.inProgress) {
  //           setTimeout(() => {
  //             return refreshAndCall(callback);
  //           }, 50);
  //         } else {
  //           Sentry.captureMessage("Fehler bei Auth");
  //           throw new Error("Fehler bei der Authentifizierung");
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("error when trying to refresh token", err);
  //         Sentry.captureException(err);
  //
  //         throw err;
  //       })
  //   : callback();
}
