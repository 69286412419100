import React from "react";
import { DataGridContainer, XButton } from "shared";
import { OrganizationService } from "../../core/api/services/OrganizationService";
import { organizationDefaultColumns } from "../../core/api/DataGridColumns/organizationDefaultColumns";
import PageReworked from "../../components/PageReworked";
import { useNavigate } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Typography } from "@mui/material";
import { routePaths } from "../../config/routePaths";

export const OrganizationsListPage = () => {
  const navigate = useNavigate();

  return (
    <PageReworked>
      <Grid2 container xs={12} rowSpacing={3}>
        <Grid2 xs={8}>
          <Typography variant="h1">Organisationen</Typography>
        </Grid2>
        <Grid2 xs={4} display={"flex"} justifyContent={"flex-end"}>
          <XButton
            variant={"contained"}
            onClick={() => {
              navigate("/organizations/new");
            }}
          >
            Neue Organisation
          </XButton>
        </Grid2>
        <Grid2 xs={12} sx={{ height: "100%" }}>
          <DataGridContainer
            queryHook={OrganizationService.listOrganizations}
            queryHookParams={{}}
            defaultColumns={organizationDefaultColumns}
            onRowClick={(row) => navigate(routePaths.organization(row.id))}
          />
        </Grid2>
      </Grid2>
    </PageReworked>
  );
};
