import Grid2 from "@mui/material/Unstable_Grid2";
import { useFormikContext } from "formik";
import { User } from "shared-ts";
import { FormikField, GenericSelector, XButton } from "shared";
import { OrganizationService } from "../../../core/api/services/OrganizationService";
import { routePaths } from "../../../config/routePaths";
import { useNavigate } from "react-router-dom";
import { userRoleMapping } from "../../../config/mappings/userRoleMapping";

export const UserForm = () => {
  const formik = useFormikContext<User>();
  const navigate = useNavigate();
  const isNew = !formik.values.id;

  const { values } = formik;

  return (
    <Grid2 container xs={12} rowSpacing={2}>
      <Grid2 xs={12}>
        <FormikField type={"text"} name={"name"} label={"Name"} />
      </Grid2>

      <Grid2 xs={12}>
        <FormikField type={"text"} name={"email"} label={"Email"} />
      </Grid2>

      <Grid2 xs={12}>
        <FormikField
          type={"password"}
          name={"password"}
          label={isNew ? "Passwort" : "Neues Passwort"}
        />
      </Grid2>

      <Grid2 xs={12}>
        <GenericSelector
          name={"organizationId"}
          label={"Organisation"}
          labelKey={"displayName"}
          entityType={"Organization"}
          listService={OrganizationService.listOrganizations}
          disabled={values.organizationId !== undefined}
        />
      </Grid2>

      <Grid2 xs={12}>
        <FormikField
          name={"role"}
          label={"Rolle"}
          type={"select"}
          options={Object.keys(userRoleMapping).map((key) => ({
            value: userRoleMapping[key].value,
            label: userRoleMapping[key].label,
          }))}
        />
      </Grid2>

      <Grid2 xs={12} display={"flex"} justifyContent={"space-between"}>
        <XButton
          variant={"outlined"}
          type={"button"}
          onClick={() => navigate(routePaths.users)}
        >
          Abbrechen
        </XButton>
        <XButton
          variant={"contained"}
          color={"primary"}
          type={"submit"}
          disabled={!formik.dirty}
        >
          Speichern
        </XButton>
      </Grid2>
    </Grid2>
  );
};
