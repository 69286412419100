/*
 *  transforms all chars in a string to lowercase and
 *  removes everything but letters and numbers. Replaces whitespaces with "-"
 */
function parseString(str: string) {
  return str
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace("ü", "ue")
    .replace("ä", "ae")
    .replace("ö", "oe")
    .replace(/[^a-z0-9.-]/gi, "");
}

function parseText(txt: string) {
  return txt.replace("&", "&amp;");
}

export { parseString, parseText };
