import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Typography } from "@mui/material";
// components
// import Iconify from '../../components/Iconify';
//
// import Searchbar from "./Searchbar";
// import AccountPopover from "./AccountPopover";
// import LanguagePopover from "./LanguagePopover";

// ----------------------------------------------------------------------
// source: https://github.com/minimal-ui-kit/material-kit-react

const DRAWER_WIDTH = 241;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

// ----------------------------------------------------------------------

Navbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function Navbar({}: {}) {
  // TODO replace with project select, run only once
  // const OrganizationQuery: UseQueryResult<{ data: [Organization] }, Error> =
  //   useQuery(["listOrganizations"], () =>
  //     OrganizationService.listOrganizations()
  //   );

  // useEffect(() => {
  //   if (!currentProjectId && OrganizationQuery.data) {
  //     const organization = OrganizationQuery.data.data[0];
  //     setCurrentProjectId(organization.projects[0].id);
  //   }
  // }, [OrganizationQuery.data]);

  return (
    <RootStyle>
      <Stack
        direction={"row"}
        sx={{
          minHeight: {
            xs: APPBAR_MOBILE,
            lg: APPBAR_DESKTOP,
          },
          padding: {
            lg: "0 2rem",
          },
        }}
      >
        {/*<IconButton*/}
        {/*    onClick={onOpenSidebar}*/}
        {/*    sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}*/}
        {/*>*/}
        {/*    <Iconify icon="eva:menu-2-fill" />*/}
        {/*</IconButton>*/}

        {/*<Searchbar />*/}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/*<LanguagePopover />*/}
          {/*<NotificationsPopover />*/}
          {/*<AccountPopover />*/}
        </Stack>
      </Stack>
    </RootStyle>
  );
}
