import { vars } from "./vars";

export const routePaths = {
  home: "/",
  organizations: "/organizations",
  organization: (id: string) => `/organizations/${id}`,
  users: "/users",
  user: (id: string) => `/users/${id}`,
  chatwootAdmin: `${vars.chatwootUrl}/super_admin`,
};
